<template>
    <div class="container" ref="container">
    </div>
</template>

<script setup>
import * as THREE from 'three'
import { onMounted, ref } from 'vue'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

// 初始化场景
const scene = new THREE.Scene();
// 初始化相机
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.z = 0.1;

// 初始化渲染器
const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight);

const container = ref(null);

// 渲染函数
const render = () => {
    renderer.render(scene, camera);
    requestAnimationFrame(render);
}

// 添加立方体
const geometry = new THREE.BoxGeometry(100, 100, 100);
// const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
var arr = ['4_l', '4_r', '4_u', '4_d', '4_b', '4_f'];
var boxMaterilas = [];

arr.forEach((itme) => {
    let texture = new THREE.TextureLoader().load(`./imags/001/${itme}.jpg`);
    boxMaterilas.push(new THREE.MeshBasicMaterial({ map: texture }));
})
const cube = new THREE.Mesh(geometry, boxMaterilas);
cube.geometry.scale(1, 1, -1);
cube.name = "cube";
scene.add(cube);



// 挂在完毕 
onMounted(() => {
    console.log("加载完毕")

    const orbitControls = new OrbitControls(camera, container.value);
    orbitControls.enableDamping = true;

    container.value.appendChild(renderer.domElement);
    render();

    // // 通过射线获取数遍点击的位置
    // const raycaster = new THREE.Raycaster();
    // const mouse = new THREE.Vector2();
    // const mouseDown = (e) => {
    //     e.preventDefault();
    //     mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    //     mouse.y = -(e.clientY / window.innerHeight) * 2 - 1;
    //     raycaster.setFromCamera(mouse, camera);
    //     const intersects = raycaster.intersectObjects(scene.children);

    //     if (intersects.length > 0) {
    //         console.log(intersects);
    //     }
    // }
    // container.value.addEventListener("mousedown", mouseDown);

    // // 创建精灵图
    // const spriteMap = new THREE.TextureLoader().load("./imags/001/up.png");
    // const spriteMeterial = new THREE.SpriteMaterial({
    //     map: spriteMap,
    //     color: 0xffffff,
    //     transparent: true,
    //     blending: THREE.AdditiveBlending,
    // });

    // const sprite = new THREE.Sprite(spriteMeterial);
    // sprite.position.set(-1, 0, -49);
    // sprite.scale.set(2, 2, 2);
    // sprite.name = "sprite";
    // scene.add(sprite);

})

</script>

<style>
* {
    margin: 0;
    padding: 0;
}

.container {
    background-color: black;
    height: 100vh;
    width: 100vw;
}
</style>
